/* .toplayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .toplayer:hover {
    opacity: 1;
  }
   */
  /* .toplayer.with-border{
    border-radius: 50%;
  }

   */
  .edit-icon {
    color: white;
    font-size: 30px;
  }