.totalPost-container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-evenly;
    margin-top: 15px;

    >div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #7FF3D5 0%, #2ECE9E 100%);
        border-radius: 27px;
        /* padding: 15px; */
        width: 70px;
        height: 67px;
    }

    >span {
        font-size: 46px;
        font-weight: 700;
    }


}


.values-kpis-posts {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    gap: 10px;

    >span {
        font-size: 34px;
        font-weight: 700;
    }
}

.tag-kpis {
    font-size: 10px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.31);
    margin-top: -10px;
}