.card-container {
    margin-top: 30px;
    padding: 20px;
  }
  
  .card-kpis {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    > h1 {
        margin:0;
        color: #9F9F9F;
        font-size: 13px;
    }
  }

  .values-kpis {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    > span{
        font-size: 40px;
    }
  }

  .title-charts {
    display: flex;
    gap: 20px;
    padding: 0px 30px;
    align-items: center;
    justify-content: space-between;

    > h1 {
        margin:0;
        color: #9F9F9F;
        font-size: 13px;

    }

  }


  .review-distribution-card {
    background-color: white;
    border-radius: 25px;
    height: 327px;
    border: none;
  }

  .review-distribution-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    max-width: 600px;
    margin: auto;
  }
  