.container {
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size:cover;
    background-repeat:no-repeat;
    padding-bottom: 10%;
}

.content {
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;

    >h1 {
        font-weight: 700;
        font-size: 30px;
        color: var(--black);
        margin: 0;
    }

    >p {
        color: #7C7C7C;
        font-size: 16px;
    }
}

.icon {
    color: #66c72d;
    font-size: 52px;
}


.button-container {
    margin-top: 3%;
}