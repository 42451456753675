.review-faq-container {
    width: 43%;
    /* background-color: white; */
    position: absolute;
    min-height: 500px;
    height: 70vh;
    overflow-y: scroll;
    left: 58%;
    top: 0;
    border-radius: 20px 0 0 0;
    box-shadow: -10px -10px 10px rgba(0, 0, 0, 0.03);
}


.card-layout {
    border-color: #D6EAF8;
    background: #f3f8fe;
    margin: 15px 0px;
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    border: solid 0px lightgrey;

}

.comment-review, .comment-answer {
    background: white;
    color: grey;
    padding: 15px;
    border-radius: 15px 15px 15px 0px;
    border: solid .3px lightgrey;
    margin-right: 30px;
}

.comment-answer {
   min-height: 160px;
}

.selected-row,
.selected-row>.ant-table-column-sort {
    background-color: var(--border-light-grey)
}

.ant-comment-nested {
    margin-left: 100px;
}

.input-field {
    background: white;
    margin: 5px;
    width: 75%;
}

.review-btn {
    float: right;
    color: black;
    font-weight: 500;
    margin-left: 10px;
}

.review-btn-grey {
    float: right;
    color: grey;
    font-weight: 500;
    margin-left: 10px;
}

.review-btn-icon {
    color: black !important;
    font-size: 20px;
    vertical-align: sub;
}

.reviews>.ant-comment-inner {
    padding: 0;
}

.no-row-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 500px;
    min-height: 70vh;
    text-align: center;
    background: white;

    >img {
        margin-top: 20px;
        width: 450px;
    }

    >h1 {
        color: var(--grey);
        font-size: 18px;
    }

}