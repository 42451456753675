@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'normalize.css';

:root { 
  --font-family: 'Poppins', sans-serif;  
  --primary: #009CDE;
  --primary-dark: #2147A7;
  --primary-light: #C8E0F0;
  --primary-light-grey: #90CBEA;
  --light-grey: #E9ECEE;
  --dark-white: #F4F7FA;
  --accent: #FFC64E;
  --accent-variant: #EA9E0C;
  --border-light-grey: #d9e9f7;
  --box-grey: #f5f9fb;
  --bussines-box-grey: #f0fbff;
  --bussines-border-light-grey: #d9e9f7;
  --blue: blue;
  --dodgerblue: dodgerblue;
  --grey: grey;
  --dimGray: DimGray;
  --title-grey: #AAADAE;
  --black: #000000;
  --white: #ffffff;
  --blue-tag: #2685C5;
  --pink: #F9BFC0;
  --turquoise: #77D7BE;
  --meat-color: #FFE7C5;
  --cyan: rgba(0, 125, 106, 0.1);
  --alpha-grey: #474747;
  --sky-blue:#2685C5;
  --active-card:rgba(45, 164, 133, 0.1);
  --material-icons: rgba(0, 0, 0, 0.3)
}



body {
  margin: 0;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* body {
  min-width: 1280px; 
} */

#smallDevice {
  display: none;
}

.campo-vacio {
  border:1px solid red;
  box-shadow: 0px 0px 4px red;
}

@media (max-width: 200px) {
  
  #largeDevice{
    display: none;
  }
  #smallDevice{
    display: block;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black !important;
  border-color: black !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: black !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-body {
  background: var(--dark-white);
  border-radius: 10px !important;
}

.ant-modal-footer {
  background: var(--dark-white) !important;
  border-radius: 0px 0px 10px 10px !important;
}


.ant-layout-sider-children {
  background: transparent !important;
}

.ant-layout-sider {
  background: transparent !important;  
}

.ant-layout-has-sider {
  background: transparent !important;  
}

.ant-switch-checked {
  background-color: var(--dimGray) !important;
}

.ant-dropdown {
  border-radius: 10px !important;
}

iframe {
  border: none;
}