

.tab-container {
    padding: 10px 30px;
}
.title-tabPane {
    font-weight: 600;
    color: #666
}

