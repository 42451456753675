.label-item-view{
  font-weight: 600;
  margin-left: 6px;
}

.item-view-product{
  height: 2.1em;
  padding-left: 17px;
  font-weight: 600;
  margin-top: 0.3em;
  color: #959494;
}

.modify-icon{
    cursor:pointer;
    margin: 5px;
    color: var(--primary)
  }