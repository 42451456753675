@import '../../../../styles/_index.sass'

.yellow
    @include button-base

    &:hover
        @include button-base ($background: var(--accent), $border-color: var(--accent))

    &:click
        @include button-base ($background: var(--accent), $border-color: var(--accent))


.black
    @include button-base($color: var(--white), $background: var(--dimGray))

    &:hover
        @include button-base($color: var(--white), $background: var(--alpha-grey), $border-color: var(--alpha-grey))

    &:click
        @include button-base($color: var(--white), $background: var(--alpha-grey), $border-color: var(--alpha-grey))


.dark-white
    @include button-base($background: var(--dark-white), $border-color: var(--alpha-grey), $color: var(--grey))

    &:hover
        @include button-base ($background: var(--light-grey), $border-color: var(--black))

    &:click
        @include button-base($background: var(--white), $border-color: var(--black))

.green
    @include button-base($background: var(--primary))

.white
    @include button-base($background: var(--white))

.no-color
    @include button-base($background: none)

