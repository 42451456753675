.locations__btns{
    display: flex;
}
.locations__logs{
    display: flex;
}
.locations__masivos{
    display: flex;
    justify-content: flex-end;
}
.locations__content-masivos{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 5px;
    /* padding: 1.5em;
    margin-top: 1em; */
}
.locations__content-masivos-border{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #D9E9F7;
    border-radius: 5px;
    padding: 1.2em;
    margin-top: 1.4em;
}
.locations__title-masivas{
    position: absolute;
    top: 8px;
    right: 271px;
    background-color: #FFFFFF;
    padding: 0 10px;
    color: #D3D6E0;
    font-style: italic;
}