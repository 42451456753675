
  
  .rating-container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;

    > div {
        display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #F3D37F 0%, #FFD913 100%);
    border-radius: 17px;
    padding: 10px;
    }

    > span {
        font-size: 40px;
        font-weight: 700;
    }
  }
  

  .review-distribution-card {
    background-color: white;
    border-radius: 25px;
    height: 327px;
    border: none;
  }

  .review-distribution-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    margin: auto;
  }

  