.tableLZM .ant-table-tbody
    background: var(--dark-white)
    box-shadow: 0px 2px 13px rgba(204, 213, 215, 0.2)
    border-radius: 50px

.ant-table-thead > tr > th
    color: var(--title-grey)
    background: var(--dark-white)      

.ant-table-small .ant-table-thead > tr > th
    color: var(--title-grey)
    background: var(--dark-white)      
    
.tableLZM .ant-table-tbody > tr.ant-table-row:hover > td
    background: var(--bussines-border-light-grey)
    color: var(--black)    


.tableLZM .ant-table-column-sort
    background: var(--dark-white) 