.icon-sync {
    float: right;
    color: lightgrey;
}

.tooltip-info {
    width: 100%;
    clear: right;
}

.tooltip-info-icon {
    font-size: 23px;
    float: right;
}
