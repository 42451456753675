

  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide-img {
    display: block;
    /* width: 100%; */
    max-height: 250px;
    object-fit: cover;
    /* width: 400px; */
  }
  