.side-menu{
    border-radius: 10px; 
    display: flex;
    flex-direction: column;
    padding: 5px; 
    background: var(--white); 
    box-shadow: 4px 7px var(--box-grey);
    border: 1px solid var(--border-light-grey); 
}

.side-menu-bussines{
    padding: 5px; 
    box-shadow: 4px 7px var( --bussines-box-grey);
    border: 1px solid var(--bussines-border-light-grey);
}

.container-space{
    padding: 10px 0px 20px 10px;
    background: var(--white);
}

.container-space .ant-card-bordered{
    background: var(--dark-white) !important;
}

.dropdown-menu{
    margin: 10px 0px; 
    text-align:right
}

.border-menu-item{
    border-radius: 10px;
}

.color-menu-item{
    color:var(--blue)
}

.icon-menu-item{
    font-size: 20px; 
}

.color-icon-menu-item{
    color:var(--dodgerblue);
}

.icon-side-opening{
    color:var(--grey);
    font-size:17px;
    margin-right:10px
}

.ant-card-bordered{
    border-radius: 20px;
}

nav{

    ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        a{
            width: 95%;

            .item-selected{
                background: var(--primary-light);
            }

            .item-no-selected{
                background: none;
            }

            .item-space{
                border-radius: 20px;
                padding:15px;
                list-style:none;
                margin-bottom: 1.5em;
            }

            div{
                display: flex;
                flex-direction: column;
                align-items: center;

                span{
                    padding-top: 3px;
                }
            }
        }
    }
}


.selected{
    color: var(--black);    
    font-size: 12px;
}

.no-selected{
    color: var(--grey);
    font-size: 12px;
}