.login-general {
    /* background-image: url('../../../assets/plane.jpg'); */
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: right top;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: left;
    align-items: center;
}

.login-general-two {
    /* bckground-image: url('../../../assets/logo-localizame-background.png'); */
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-header{
    background-image: url('../../../assets/localizame-oficial.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
    height: 80px;
    width: 213px;
    background-position-x: left;
}

.login-card {
    border-radius: 50%;
    padding: 40px;
    width: 670px;
    height: 670px;
    align-content: right;
    margin-left: 3vw;
    background: var(--sky-blue);
    position: relative;
}

.login-form{
    position: absolute;
    top: 20%;
    width: 70%;
    left: 25%;
}

.missing-password {
    color: #4094d0;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    border: none;
    margin-top: 8px;
    color: var(--white);
}

.missing-password:hover {
    color: #68b1de;
}

.ant-input-affix-wrapper{
    border-radius: 15px;
}

.login-card .ant-form-vertical .ant-form-item-label > label {
    color: var(--white);
    font-size: 14px;
}

.title{
    font-weight: 700;
    font-size: 40px;
    color: var(--white);
    margin-bottom: 0px;
}

.title-header{
    font-size: 30px;
    color: var(--white);
    font-weight: 400;
}

.not-member{
    color: var(--white);
    font-size: 18px;
    font-weight: 200;
}

.link-redirect-to-register{
    color: var(--white);
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid var(--white);
}

.header{
    padding-bottom: 33px;
}

input::placeholder {
    font-size: 14px;
    font-style: italic;
}

.material-symbols-outlined{
    color:  var(--material-icons);
}


.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
    padding-bottom: 4px;
}

.ant-form-item{
    margin-bottom: 16px;
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: var(--accent-variant)
}



.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    

}

.container >span{
    position: absolute;
    box-shadow: 0 0 4px var(--sky-blue), inset 0 0 1px var(--sky-blue);
     border-radius: 50%;
    transform: translate(50%,50%);
}

.span1{
    bottom: 31%;
    right: 40%;
    animation: ripple 5s linear infinite;

}

.span2{
    bottom: 90%;
    right: 10%;
    animation: ripple 5s linear infinite;
    animation-delay: 2s;

}

.span3{
    bottom: 85%;
    right: 50.5%;
    animation: ripple 5s linear infinite;
    animation-delay: 4s;

}

.span4{
    bottom: 6%;
    right: 60%;
    animation: ripple 5s linear infinite;
    animation-delay: 2s;

}

.span5{
    bottom: 10%;
    right: 13%;
    animation: ripple 5s linear infinite;
    animation-delay: 8s;

}

.span6{
    bottom: 20%;
    right: 96%;
    animation: ripple 5s linear infinite;
    animation-delay: 8s;
}

.span7{
    bottom: 55%;
    right: 20%;
    animation: ripple 5s linear infinite;
    animation-delay: 2s;
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
    }

    90% {
        width: 150px;
        height: 150px;
    }

    100% {
        opacity: 0;
    }
}
