.card-content {
    display: flex;
    gap: 0 50px;
    margin-top: 25px;

    .card-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 15px;
        max-width: 300px;
        min-width: 300px;
        height: 100%;

        .preview-footer {
            text-align: center;
            margin: 0;
        }
    }

    .card-settings {
        flex-grow: 1;

        .settings-group {
            display: flex;
            align-items: center;
            gap: 0 20px;
        }
    }

    
}