

.container-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 999;
}


.details-header {
   
    width: 100%;
    align-items: center;
    gap: 2rem !important;
}

.details-header-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin-top:15px
}


.status-header {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 8px;
    min-width: 30px !important;
}

.container-storeCode{
    width: 84px;
    min-height: 34px;
    border-radius: 25px;
    background-color: #FFE7C5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-verify{
    width: 30px;
    height: 34px;
    border-radius: 25px;
    background-color: #77D7BE;
    display: flex;
    justify-content: center;
    align-items: center;
}


.address-group-layout {
    font-weight: 500;
    color: #AAADAE
}

.portada-layout {
    font-size: 13px;
    background: '#F4F7FA';
    min-width: 300px;

}

.portada-pic-card {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    column-gap: 4px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.portada-pic-img {
    width: 100%;
    height: auto;
    padding: 0 15px;
    border-radius: 10px;
    object-fit: cover;
}

.detail-info-card {
    background: ghostwhite;
    margin: 15px;
    border-radius: 3px;
}

.more-pics-btn {
    color: #4094d0;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    border: none;
    transition: color 0.3s;
    margin-left: 1em;
    font-weight: 600;
}

.more-pics-btn:hover {
    gap: 12px;
    justify-content: center;
    align-items: flex-start;
}

.info-name-group {
    display: flex;
    flex-direction: column;
    gap: 1px;
    justify-content: center;
    align-items: flex-start;
}

.title-box {
    display: flex;
    gap: 12px;
    padding: 0px 1em;
    box-sizing: border-box;
    width: 100%;
}

.phone-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
    padding: 0px 1em;

}

.url-box div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 1em;

}

.category-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 1em;
}

.description-box {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 10px 1em;
}

.location__detail-regularHours:hover{
    background-color: #ececf0;
}

.content-style {
    margin: 10px;
    height: 200px;
    color: #fff;
    line-height: 160px;
    text-align: center;
    background: #364d79;
    border-radius: 25px;
};
