.title-card {    
    font-weight: 700;
    font-size: 28px;
    line-height: 22px;
    padding-top: 20px;
    margin: 0;
}

.main-card-content {
    border: solid .2px lightgrey;
    padding-top: 20px;
    padding-left: 25px;
    min-height: 100vh;
    border-radius: 20px 0px;
}