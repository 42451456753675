.header{ 
    background: white; 
    display: flex; 
    justify-content: space-between;
    padding: 0px 25px;
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 28px;
    align-items: center;
    height:95px;
}


.accountOverlay-notifications{
    border-radius:10px;
    box-shadow: 0px 0px 20px -8px;
    margin: 8px;
    background-color: white;
    flex-direction: column;
    gap: 15px;
    padding: 20px 11px;
    // box-shadow: var(--shadow);
    // background: var(--light-grey);
    margin-top: 10px;
    width: 25em;
    /* height: 60em; */
    max-height: 91vh;

    p{
        color: var(--alpha-grey)}

    h5{
        font-size: 18px;
        text-align: center;
    }
 

} 

.overlays-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
}

.ant-menu-horizontal{
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 0px;
    align-items: center;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
    color: var(--black) !important;
    font-weight: 500px;
    font-size: 16px;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
    color: var(--black);
    font-weight: 500px;
    font-size: 16px;
}

.ant-menu-horizontal > .ant-menu-item a{
    color: var(--grey);
    font-weight: 500px;
    font-size: 16px;
}

.profile-email{
    font-size: 10px;
    color: var(--grey);
    font-weight: 300;
}

.ant-menu-item .ant-menu-item-icon, 
.ant-menu-submenu-title .ant-menu-item-icon, 
.ant-menu-item .anticon, 
.ant-menu-submenu-title .anticon{
    margin-right: 13px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 6px solid var(--blue-tag) !important;
    border-radius: 10px;
}

.ant-select .ant-select-borderless .ant-select-single .ant-select-show-arrow{
    min-width: 42px !important;
}

.buton-profile{
    border-radius: 0px;
    border-color: white;
    font-size: 16px;
    color: var(--black);
    font-weight: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

