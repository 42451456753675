  
 

  .loc-values-twice-kpis {    
    font-size: 24px;
  }
 
 
  .locations-container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin-top: 10px;

    > div {
        display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #7fc7f3 0%, #13e7ff 100%);
    border-radius: 17px;
    padding: 10px;
    }

    > span {
        font-size: 40px;
        font-weight: 700;
    }
  }
  
