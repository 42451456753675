.main-card-body {
    background: white;
    border-radius: 27px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.main-card-content-left {
    width: 605px;

    >h1 {
        font-weight: 700;
        font-size: 40px;

        >span {
            color: #07A6FF;
        }

        ;
    }

    >p {
        color: #7C7C7C;
    }

    ;
}


.welcome-person-img {
    position: absolute;
    top: 11px;
    right: 10%;
}

.card-section {
    display: flex;
    gap: 30px;

    >div {
        width: 594px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}


.card-item-title {
    color: #fff;
    font-weight: 700;
    font-size: 35px;
}

.card-item-img {
    position: absolute;
    right: 0;
    bottom: 0;
}

.card-section-right {
    margin-left: 20px;

    > p {
        color: #7C7C7C;
    }

    > div {
        display:flex;
        gap:10px
    }
}

.circle-icon {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: rgba(4, 172, 244, 0.22);
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-icon-inner {
    font-size: 20px;
    color: #07A6FF;
}

.circle-icon-title {
    font-weight: 700;
    font-size: 27px;
}

